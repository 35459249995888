import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import Container from "@components/Container";
import Div from "@components/Div";
import { H3 } from "@components/Heading";
import LegacyReport from "@components/Report/LegacyReport/LegacyReport";

import ShareHeader from "@pages/candidate/ShareHeader";

import { BANK_ID_SCOPES } from "@utils/enum";

import BankIDLogin from "../BankidComponent/BankIDLogin";

export const PendingConsentToShare = ({
  companyName = "",
  consentId,
  report = {},
  onHandleUpdate = () => {},
  onError,
}) => {
  const { messages } = useIntl();
  const { personal_number: ssn } = report;

  const handleSuccess = () => {
    onHandleUpdate("completed");
  };

  const handleError = useCallback(
    error => {
      onError(error);
    },
    [onError]
  );

  return (
    <>
      <Div zIndex={1} bg="#F8F9FB" left={0} right={0}>
        <Container>
          <Div
            display="flex"
            flexDirection={["column", "row"]}
            px={[3, 3, 3, 3]}
          >
            <Div
              display="flex"
              flexDirection="column"
              width={1}
              justifyContent="space-between"
              alignContent={["center", "center", "center", "flex-start"]}
              py={[3, 4]}
            >
              <H3 pb={3}>{messages.label_consent_ready_review_title}</H3>
              <Div lineHeight={"var( --lh-h3)"} pr={[0, 5]} pt={2}>
                {messages.label_consent_ready_review_description}
              </Div>
            </Div>
          </Div>
        </Container>

        <Div bg="#EFF1F5">
          <Container px={3} py={2}>
            <ShareHeader orderDetails={report} />
          </Container>
        </Div>
      </Div>

      <Div>
        <LegacyReport
          report={{ ...report, product: report.order_item.product }}
          showDownloadPDF={false}
        />
      </Div>

      <Div zIndex={1} bg="#fcf1df" left={0} right={0} mb={"100px"}>
        <Container>
          <Div px={3} pt={[3, 4]} pb={"24px"}>
            <H3>{messages.consent_ready_to_share}</H3>
          </Div>

          <Div
            display="flex"
            flexDirection={["column", "row"]}
            px={[3, 3, 3, 3]}
          >
            <Div
              display="flex"
              flexDirection="column"
              width={1}
              justifyContent="space-between"
              alignContent={["center", "center", "center", "flex-start"]}
            >
              <Div lineHeight={"var( --lh-h3)"} pr={[0, 5]}>
                {`${messages.consent_ready_for_sharing} ${companyName}.
                ${messages.consent_ready_for_sharing_2}`}
              </Div>
            </Div>

            <Div
              display="column"
              flexDirection={["column", "row", "row", "row"]}
              alignContent="center"
              pb={[0, 4]}
              width={[1, 1, 1, "280px"]}
            >
              <Div alignContent="center" flexDirection="row" mb={4}>
                <BankIDLogin
                  ssn={ssn}
                  scope={BANK_ID_SCOPES.LEGACY_CONSENT_SHARE}
                  userNonVisibleData={consentId}
                  onSuccess={handleSuccess}
                  onError={handleError}
                />
              </Div>

              <Div display="flex" flexDirection="column"></Div>
            </Div>
          </Div>
        </Container>
      </Div>
    </>
  );
};

PendingConsentToShare.propTypes = {
  companyName: PropTypes.string,
  consentId: PropTypes.string,
  report: PropTypes.object,
  onHandleUpdate: PropTypes.func,
  onError: PropTypes.func,
};

export default PendingConsentToShare;
