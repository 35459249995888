import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { ButtonLinkIcon, PrimaryButtonIcon } from "@components/Button";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import { H3 } from "@components/Heading";
import Icon from "@components/Icon";
import { Text } from "@components/Text";

const BankIdInitMobileDialog = ({
  handleUseApp,
  handleUseAnotherDevice,
  onClose,
}) => {
  const { messages } = useIntl();

  return (
    <Dialog
      visible="displayBasic"
      draggable={false}
      width={1}
      maxWidth="450px"
      onHide={onClose}
      m={[3, 3, 3, "auto"]}
    >
      <Div
        display="flex"
        flexDirection="column"
        alignItems="center"
        gridGap="20px"
      >
        <Icon
          name="bankID"
          fontSize="var(--fs-icon-xl)"
          color="var(--blue-bank-id-logo)"
        />

        <H3 textTransform="none">{messages.title_bankid_login}</H3>

        <Text textAlign="center">{messages.message_bank_id_mobile_init}</Text>

        <PrimaryButtonIcon
          label={messages.label_initiate_bank_id}
          onClick={handleUseApp}
          icon="icon-bankID"
        />

        <ButtonLinkIcon
          height="40px"
          label={messages.label_use_bank_id_on_another_device}
          onClick={handleUseAnotherDevice}
          icon="icon-qrcode"
        />
      </Div>
    </Dialog>
  );
};

BankIdInitMobileDialog.propTypes = {
  handleUseApp: PropTypes.func,
  handleUseAnotherDevice: PropTypes.func,
  onClose: PropTypes.func,
};

export default BankIdInitMobileDialog;
