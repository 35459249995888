import React, { useCallback, useMemo } from "react";
import QRCode from "react-qr-code";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import {
  ButtonLinkIcon,
  PrimaryButton,
  PrimaryButtonOutlined,
} from "@components/Button";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import Icon from "@components/Icon";
import { Text, TextSemiBoldWeight } from "@components/Text";

import {
  BANKID_MESSAGE_TERM,
  COMPLETE_STATUS,
  FAILED_STATUS,
  PENDING_STATUS,
} from "@utils/constant";

const BankIDModal = ({
  hideModal,
  qrCodeData,
  bankIDFeedback,
  onTryAgain,
  onUseApp,
}) => {
  const { messages } = useIntl();

  const { status = "", message_term: messageTerm = "" } = bankIDFeedback;

  const parseFeedback = useCallback(() => {
    if ((!status && !messageTerm) || status === COMPLETE_STATUS) {
      return "";
    }

    if (status === PENDING_STATUS) {
      switch (messageTerm) {
        case BANKID_MESSAGE_TERM.OUTSTANDING_TRANSACTION:
        case BANKID_MESSAGE_TERM.NO_CLIENT:
          return messages.message_bankID_start_your_bankID;

        case BANKID_MESSAGE_TERM.USER_SIGN:
          return messages.message_bankID_enter_security_code;

        case BANKID_MESSAGE_TERM.STARTED:
          return messages.message_bankID_searching;

        case BANKID_MESSAGE_TERM.USER_MRTD:
          return messages.message_bankID_process_travel_document;

        default:
          return messages.message_bankID_identification_in_progress;
      }
    } else if (status === FAILED_STATUS) {
      switch (messageTerm) {
        case BANKID_MESSAGE_TERM.USER_CANCEL:
          return messages.message_bankID_action_cancelled;

        case BANKID_MESSAGE_TERM.EXPIRED_TRANSACTION:
          return messages.message_bankID_not_responding;

        case BANKID_MESSAGE_TERM.CERTIFICATE_ERR:
          return messages.message_bankID_blocked;

        case BANKID_MESSAGE_TERM.START_FAILED:
          return messages.message_bankID_not_found;

        default:
          return messages.message_bankID_unknown_error;
      }
    } else {
      switch (messageTerm) {
        case BANKID_MESSAGE_TERM.CANCELLED:
          return messages.message_bankID_cancelled;

        case BANKID_MESSAGE_TERM.ALREADY_IN_PROGRESS:
          return messages.message_bankID_already_started;

        default:
          return messages.message_bankID_internal_error;
      }
    }
  }, [messages, status, messageTerm]);

  const isBankIdFailed = useMemo(
    () =>
      status === PENDING_STATUS &&
      messageTerm === BANKID_MESSAGE_TERM.START_FAILED,
    [messageTerm, status]
  );

  const title = useMemo(
    () =>
      isBankIdFailed
        ? messages.label_bankID_login_failed
        : messages.label_log_in_with_bankID,
    [
      isBankIdFailed,
      messages.label_bankID_login_failed,
      messages.label_log_in_with_bankID,
    ]
  );

  return (
    <Dialog
      visible="displayBasic"
      draggable={false}
      width={[1, 445, 445, 445]}
      onHide={hideModal}
      m={[3, 3, "auto", "auto"]}
    >
      <Div
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gridGap="20px"
      >
        <Icon
          name="bankID"
          color="var(--blue-bank-id-logo)"
          fontSize="var(--fs-icon-xl)"
        />

        <TextSemiBoldWeight textAlign="center">{title}</TextSemiBoldWeight>

        {status && messageTerm && (
          <Text textAlign="center">{parseFeedback()}</Text>
        )}

        {qrCodeData && !isBankIdFailed && (
          <QRCode size={250} value={qrCodeData} />
        )}

        {isBankIdFailed && (
          <PrimaryButton
            width="150px"
            justifyContent="center"
            onClick={onTryAgain}
          >
            {messages.label_try_again}
          </PrimaryButton>
        )}

        <PrimaryButtonOutlined
          width="150px"
          label={messages.label_cancel}
          onClick={hideModal}
        />

        <ButtonLinkIcon
          label={messages.label_open_bank_id_on_device}
          onClick={onUseApp}
          icon="icon-bankID"
        />
      </Div>
    </Dialog>
  );
};

BankIDModal.propTypes = {
  hideModal: PropTypes.func,
  qrCodeData: PropTypes.string,
  bankIDFeedback: PropTypes.object,
  onTryAgain: PropTypes.func,
  onUseApp: PropTypes.func,
};

export default BankIDModal;
