import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import getReportByConsentIdService from "@app/services/candidate/getReportByConsentIdService";

import ConsentExpired from "@components/Consent/ConsentExpired";
import ConsentGiven from "@components/Consent/ConsentGiven";
import PendingConsent from "@components/Consent/PendingConsent";
import PendingConsentToShare from "@components/Consent/PendingConsentToShare";
import ReportNotReady from "@components/Consent/ReportNotReady";
import Div from "@components/Div";
import ProgressSpinner from "@components/ProgressSpinner";

import { useToast } from "@hooks/useToast";

const consentComponents = {
  "Pending Consent": PendingConsent,
  "Pending consent to share": PendingConsentToShare,
  failed: ReportNotReady,
  "in-progress": ReportNotReady,
  completed: ConsentGiven,
  "expired-no-consent": ConsentExpired,
};

export const CandidateConsent = () => {
  const { messages } = useIntl();
  const { consent_id } = useParams();
  const { showErrorToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [currentReport, setCurrentReport] = useState({});

  const urlParams = new URLSearchParams(window.location.search);
  const query_status = urlParams.get("query_status");

  const getReport = useCallback(async consent_id => {
    const response = await getReportByConsentIdService(consent_id);
    const [orderItem] = response.data;
    const { report = {} } = orderItem;

    setCompanyName(orderItem.customer_name);
    setCurrentReport(report);

    return report;
  }, []);

  useEffect(() => {
    if (consent_id && query_status === null) {
      setTimeout(() => {
        try {
          setLoading(true);

          getReport(consent_id);
        } catch (error) {
          showErrorToast(messages.exception_error_message);
        } finally {
          setLoading(false);
        }
      }, 2000);
    }
  }, [
    consent_id,
    getReport,
    messages.exception_error_message,
    query_status,
    showErrorToast,
  ]);

  const handleUpdate = async () => {
    try {
      let intervalId;
      const timeout = 30000;

      setLoading(true);

      await new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
          clearTimeout(timeoutId);
          stopAuthorization();
          reject(new Error("Report generation in progress timed out"));
        }, timeout);

        const stopAuthorization = async () => {
          clearInterval(intervalId);
          clearTimeout(timeoutId);
        };

        intervalId = setInterval(async () => {
          const report = await getReport(consent_id);
          const { report_status } = report;

          if (report_status !== "in-progress") {
            stopAuthorization();
            resolve(report);
          }
        }, 5000);
      });
    } catch (error) {
      showErrorToast(messages.exception_error_message);
    } finally {
      setLoading(false);
    }
  };

  const handleError = error => {
    showErrorToast(error ?? messages.exception_error_message);
  };

  if (
    !Object.keys(consentComponents).includes(currentReport.report_status) ||
    loading
  ) {
    return <ProgressSpinner />;
  }

  const Component = consentComponents[currentReport.report_status];

  return (
    <Div
      width={["100%", "100%", "100%", "1174px"]}
      paddingLeft={["0", "16px", "16px", "32px"]}
      paddingRight={["0", "16px", "16px", "32px"]}
    >
      <Component
        companyName={companyName}
        report={currentReport}
        consentId={consent_id}
        onHandleUpdate={handleUpdate}
        onError={handleError}
      />
    </Div>
  );
};

export default CandidateConsent;
